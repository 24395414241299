import React, { useRef, useEffect } from "react"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import gsap from "gsap"
import { Power3 } from "gsap/gsap-core"
import { OutlineButton } from "../components/button"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: "0% 18% 1% 18%",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 5% 5%",
      marginTop: "10%",
    },
  },
  itemWrapper: {
    textAlign: "center",
  },
  title: {
    opacity: 0,
    fontSize: "5em",
  },
}))

const NotFound = () => {
  let title = useRef(null)

  useEffect(() => {
    gsap.to(title, 1, { opacity: 1, y: -20, ease: Power3.easeOut })
  }, [])

  const classes = useStyles()
  return (
    <Layout>
      <Grid container className={classes.container}>
        <Grid item sm={12} className={classes.itemWrapper}>
          <h2 className={classes.title} ref={title}>
            4 0 4
          </h2>
          <p>페이지가 없네요. 메인 페이지로 돌아가주세요</p>
          <OutlineButton to="/" buttonName={"홈으로"} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default NotFound
