import React from "react"
import { ThemeProvider, CssBaseline } from "@material-ui/core"
import theme from "../styles/Theme"
import MobileMenuBar from "./mobileHeader"
import { Footer } from "./footer"
import "../styles/component.css"

const Layout = props => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MobileMenuBar />
      <div className="root">{props.children}</div>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
