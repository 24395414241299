import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import { Grid, List, ListItem, Hidden } from "@material-ui/core"
import { Logo } from "./logo"
const useStyles = makeStyles(theme => ({
  fContainer: {
    padding: "5% 16%",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5%",
    },
  },
  fCategory: {
    fontFamily: '"Karla", sans-serif',
    fontSize: "0.9rem",
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#615e5e",
    letterSpacing: "0.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  fList: {
    fontSize: "0.8rem",
    fontFamily: '"Karla", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.3em",
    color: "#828282",
  },
  fItem: {
    paddingLeft: 0,
    "&:hover": {
      color: "#3b5280",
      transition: "color 1s ease",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  fItemNarrow: {
    paddingLeft: 0,
    letterSpacing: "0.1em",
    textTransform: "none",
    "&:hover": {
      color: "#3b5280",
      transition: "color 1s ease",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  ahref: {
    textDecoration: "none",
    color: "#828282",
    "&:hover": {
      color: "#3b5280",
      transition: "color 1s ease",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  copyright: {
    fontSize: "0.6em",
    color: "#828282",
    letterSpacing: "0.3em",
  },
  hr: {
    border: "0.5px solid #ecebeb",
    marginTop: "5%",
    width: "90%",
  },
  companyInfo: {
    fontSize: "0.7em",
    color: "#615e5e",
    letterSpacing: "0.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.3em",
    },
  },
}))

export const Footer = () => {
  const classes = useStyles()

  return (
    <>
      <hr className={classes.hr} />
      <Grid container className={classes.fContainer}>
        <Hidden only="xs">
          <Grid item sm={3} xs={12}>
            <p className={classes.fCategory}>navigation</p>
            <List className={classes.fList}>
              <ListItem
                className={classes.fItem}
                button
                component={Link}
                to="/"
                activeStyle={{ color: "#3b5280" }}
              >
                Home
              </ListItem>
              <ListItem
                className={classes.fItem}
                button
                component={Link}
                to="/about"
                activeStyle={{ color: "#3b5280" }}
              >
                about
              </ListItem>
              <ListItem
                className={classes.fItem}
                button
                component={Link}
                to="/portfolio"
                activeStyle={{ color: "#3b5280" }}
              >
                portfolio
              </ListItem>
              <ListItem
                className={classes.fItem}
                button
                component={Link}
                to="/gallery"
                activeStyle={{ color: "#3b5280" }}
              >
                gallery
              </ListItem>
              <ListItem
                className={classes.fItem}
                button
                component={Link}
                to="/contact"
                activeStyle={{ color: "#3b5280" }}
              >
                contact
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid item sm={3} xs={12}>
          <p className={classes.fCategory}>Social links</p>
          <List className={classes.fList}>
            <ListItem className={classes.fItem}>
              <a
                href="https://www.instagram.com/magan_project/"
                className={classes.ahref}
                target="_blank"
                rel="noopener noreferrer"
              >
                instagram
              </a>
            </ListItem>
            <ListItem className={classes.fItem}>
              <a
                href="https://blog.naver.com/maganproject/"
                className={classes.ahref}
                target="_blank"
                rel="noopener noreferrer"
              >
                naver blog
              </a>
            </ListItem>
            <ListItem className={classes.fItem}>kakaotalk</ListItem>
          </List>
        </Grid>
        <Grid item sm={3}>
          <p className={classes.fCategory}>Contact info</p>
          <List className={classes.fList}>
            <ListItem
              className={classes.fItem}
              button
              component="a"
              href="tel:010-6583-0113"
            >
              010.6583.0113
            </ListItem>
            <ListItem
              className={classes.fItemNarrow}
              button
              key="Email"
              component="a"
              href="mailto:hongsg0113@naver.com"
            >
              hongsg0113@naver.com
            </ListItem>
            <ListItem
              className={classes.fItemNarrow}
              button
              component="a"
              href="http://naver.me/5LfzbQuZ"
              target="_blank"
              rel="noopener noreferrer"
            >
              서울 마포구 와우산로10길 29
            </ListItem>
          </List>
        </Grid>
        <Hidden only="xs">
          <Grid item sm={3} style={{ textAlign: "right" }}>
            <Logo width={"160px"} fill={"#223232"} />
          </Grid>
        </Hidden>
        <Grid item sm={12}>
          <p className={classes.copyright}>
            Magan Project {new Date().getFullYear()} &copy; all rights reserved
          </p>
          <p className={classes.companyInfo}>
            매건프로젝트 | 사업자등록번호 650-33-00588 | 대표이사 홍슬기
          </p>
        </Grid>
      </Grid>
    </>
  )
}
