import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, ListItem } from "@material-ui/core"
import { Link } from "gatsby"
const useStyles = makeStyles(theme => ({
  outlined: {
    borderRadius: 0,
    borderColor: "rgba(0, 0, 0, 0.15)",
    padding: "7px 30px",
    fontSize: "1.15em",
    color: "#615d5d",
    letterSpacing: "0.2em",
    "&:hover": {
      color: "#3b5280",
      transition: "color 1s ease",
    },
  },
  regularLinks: {
    fontSize: "0.9em",
    color: "#3b5280",
    "&:hover": {
      color: "#615d5d",
      fontWeight: 600,
      transition: "color 1s ease",
    },
  },
}))

export const OutlineButton = props => {
  const classes = useStyles()

  return (
    <div>
      <Button
        variant="outlined"
        component={Link}
        className={classes.outlined}
        to={props.to}
      >
        {props.buttonName}
      </Button>
    </div>
  )
}

export const RegularLinks = props => {
  const classes = useStyles()

  return (
    <div>
      <ListItem component={Link} className={classes.regularLinks} to={props.to}>
        {props.linkName}
      </ListItem>
    </div>
  )
}
